<template>
  <li class="list-item">
    <ul class="innerList">
      <li class="meta">
        <div class="meta-name">
          {{ name }}
        </div>
        <btnToggle class="on" :btnTxt="timeTxt" color="" :class="timeClass" />
      </li>
      <li class="box">{{ boxNum }}</li>
      <li class="vacuum">内 真空</li>
      <li class="innerList-item" v-for="product in items" :key="product.id">
        <div class="itemRow">
          <span class="quantity" :class="{ positive: product.total_num > 0 }">
            <span class="num">{{ product.total_num }}</span>
            <span class="unit">{{ product.unit }}</span>
          </span>
          <span
            class="vacuumQuantity"
            :class="{ positive: product.bacuum_num > 0 }"
          >
            <span class="num">{{ product.bacuum_num }}</span>
            <span class="unit">{{ product.unit }}</span>
          </span>
        </div>
        <div v-if="edit" class="amount-btn">
          <div
            @click="
              minus({
                products: items,
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item minus"
          ></div>
          <div
            @click="
              plus({
                products: items,
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item plus"
          ></div>
          <div
            @click="
              vacuumMinus({
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item minus"
          ></div>
          <div
            v-on:click="
              vacuumPlus({
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item plus"
          ></div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapMutations } from "vuex";
import btnToggle from "@/components/btn/btnToggle";
export default {
  name: "instructionDetailShopItem",
  components: {
    btnToggle
  },
  data: function() {
    return {
      timeTxt: "",
      timeClass: ""
    };
  },
  computed: {
    order: function() {
      return this.$store.state.iseyaOrder.data.order;
    }
  },
  props: {
    name: String,
    date: String,
    dayName: String,
    time: String,
    edit: Boolean,
    day: Number,
    boxNum: Number,
    orderNum: Number,
    items: Array
  },
  mounted() {
    if (this.time == "pm") {
      this.timeTxt = "指定日PM";
      this.timeClass = "positive2";
    }
    if (this.time == "am") {
      this.timeTxt = "翌日AM";
      this.timeClass = "negative";
    }
  },
  methods: {
    ...mapMutations(["plus", "minus", "vacuumPlus", "vacuumMinus"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.list-item {
  width: calc(100% / 8);
  min-width: 160px;
}

.meta {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  box-sizing: border-box;
  padding: 9px 0;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  .btnToggle {
    margin-top: auto;
  }
}

.meta-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 3px;
  box-sizing: border-box;
  padding: 0 15px;
  text-align: center;
}

.box {
  height: 30px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 15px;
  @extend .f_body;
}

.vacuum {
  height: 30px;
  color: variables.$scrollbar-back;
  @extend .f_body;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: solid 1px variables.$bg-gray-2;
  padding-right: 17px;
}

.innerList-item {
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}

.itemRow {
  display: flex;
  padding: 15px;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 60px;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.quantity,
.vacuumQuantity {
  opacity: 0.2;
  transition: all 0.5s ease-in-out;
  &.positive {
    opacity: 1;
  }
}

.quantity {
  color: variables.$txt-default;
  width: 52px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.vacuumQuantity {
  color: variables.$scrollbar-back;
  width: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.num {
  font-size: 2rem;
  letter-spacing: 0;
  white-space: nowrap;
}

.unit {
  margin: 0 0 3px 5px;
  letter-spacing: 0;
  white-space: nowrap;
}

.amount-btn {
  display: flex;
  box-sizing: border-box;

  border-right: solid 1px variables.$bg-gray-2;
}
.amount-btn__item {
  width: 25%;
  height: 40px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  &:before {
    content: "";
    width: 12px;
    height: 1px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.plus {
  &:after {
    content: "";
    width: 1px;
    height: 12px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
